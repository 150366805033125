let _data = null

export function initConfig(data) {
	_data = data
}

export function getUrl(key, locale) {
	let result = null
	if (_data) {
		result = _data
				?.links
				?.[locale]
				?.[key]
		if (!result) {
			result = _data
				?.links
				?.common
				?.[key]
		}
	}
	return result
}
