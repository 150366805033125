<template>
<main>
    <div class="login margin--bottom-xl padding--top-lg">
        <div class="container">
            <div class="login__wrap">
                <div class="login__col-50">
                    <form id="loginform"
                        ref="loginform"
                        class="padding--bottom-sm"
                        data-form-validate="data-form-validate"
                        data-form-disable-submit="disable"
                        v-if="auth"
                        v-on:bouncerFormValid="submitForm">
                        <fieldset class="fieldset">
                            <legend class="text-size--xl">{{$t('login.headline')}}</legend>
                            <div class="login__paragraph">{{$t('login.message')}}</div>
                            <div v-for="callback in auth.callbacks" v-bind:key="callback._id">
                                <div class="form-row padding--top-xs" v-if="callback.type === 'NameCallback'">
                                    <div class="form-field">
                                        <div class="text-input-wrapper text-input--active is--valid">
                                            <label class="field-label" for="tbEmail">{{$t('login.username')}}</label>
                                            <input id="tbEmail"
                                                   type="email"
                                                   name="email"
                                                   class="text-input js-floating-label is--valid"
                                                   required="required"
                                                   autocomplete="email"
                                                   :title="$t('login.username')"
                                                   :data-error-message="$t('login.username_missing')"
                                                   :data-bouncer-pattern-mismatch-message="$t('login.username_wrong')"
                                                   v-model.trim="callback.input[0].value"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row padding--bottom-xs" v-if="callback.type === 'PasswordCallback'">
                                    <div class="form-field">
                                        <div class="text-input-wrapper text-input--active is--valid">
                                            <label class="field-label" for="tbPassword">{{$t('login.password')}}</label>
                                            <input id="tbPassword"
                                                   type="password"
                                                   name="password"
                                                   class="text-input js-floating-label is--valid"
                                                   required="required"
                                                   autocomplete="password"
                                                   :title="$t('login.password')"
                                                   :data-error-message="$t('login.password_missing')"
                                                   v-model.trim="callback.input[0].value"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div aria-live="assertive" aria-atomic="true" v-if="error">
                            <div class="alert alert--error js-error-summary" data-frontend-validated="true" tabindex="-1" aria-labelledby="error-summary-heading">
                                <span v-html="$t('messages.missingRequiredInputs')" v-if="error === 'messages.missingRequiredInputs'"></span>
                                <span v-html="$t('messages.login_failure')" v-if="error === 'messages.login_failure'"></span>
                                <span v-html="$t('messages.authentication_timeout')" v-if="error === 'messages.authentication_timeout'"></span>
                            </div>
                        </div>
                        <button type="submit" class="btn btn--primary btn--lg btn--submit align--left">
                            <span>{{$t('login.button')}}</span>
                            <span class="asf-arrow-right"></span>
                        </button>
                    </form>
                    <a v-bind:href="getUrl('app_resetPwd', $i18n.locale) + getTargetUrlParam()" class="anchor">{{$t('login.pwForgot')}}</a>
                </div>
                <div class="login__col-50">
                    <fieldset class="fieldset">
                        <legend class="text-size--xl">{{$t('register.headline')}}</legend>
                    </fieldset>
                    <div class="login__paragraph">{{$t('register.message')}}</div>
                    <ul class="login__icon-list padding--top-xs padding--bottom-xs">
                        <li class="nav-login__item">
                            <a v-bind:href="getUrl('services_asfinag', $i18n.locale)" class="nav-login__item-link">
                                <span class="round-icon asf-and-logo" aria-hidden="true"></span>
                                <span class="nav-login__item-name">{{$t('services.asfinag')}}</span>
                            </a>
                        </li>
                        <li class="nav-login__item">
                            <a v-bind:href="getUrl('services_shop', $i18n.locale)" class="nav-login__item-link">
                                <span class="round-icon asf-shopping-cart-full" aria-hidden="true"></span>
                                <span class="nav-login__item-name">{{$t('services.shop')}}</span>
                            </a>
                        </li>
                        <li class="nav-login__item">
                            <a v-bind:href="getUrl('services_app', $i18n.locale)" class="nav-login__item-link">
                                <span class="round-icon asf-app-download" aria-hidden="true"></span>
                                <span class="nav-login__item-name">{{$t('services.app')}}</span>
                            </a>
                        </li>
                    </ul>
                    <a v-bind:href="getUrl('app_addAccount', $i18n.locale) + getTargetUrlParam()" class="btn btn--secondary btn--lg align--left">
                        <span>{{$t('register.button')}}</span>
                        <span class="asf-arrow-right" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import axios from "axios"

import { getUrl } from "@/config"
import { initFrontend } from "../assets/js/kw-myasf-login-lib.js"
import { nextTick } from "vue"

export default {
  name: "UserLogin",

  data: function () {
    return {
      auth: null,
      error: null
    }
  },

  created: function () {
    this.startAuthentication()
  },

  mounted: async function () {
    // call the imported function
    // nextTick() will postpone the function call to the next render cycle to
    // be on the safe side in case other DOM manioulations happen in mounted,
    // make sure to run this function last
    await nextTick()
    initFrontend()
  },

  computed: {
    authenticateUrl: function () {
      return "/auth/json/realms/root/realms/consumer/authenticate?" + this.updateLocale(window.location.search)
    }
  },

  methods: {
    doAuthenticate (data) {
      axios
        .post(
          this.authenticateUrl,
          JSON.parse(JSON.stringify(data)),
          {
            headers: {
              "Accept-API-Version": "protocol=1.0,resource=2.1",
              "Content-Type": "application/json"
            }
          })
        .then(
          (response) => {
            this.error = null
            this.onAuthenticate(response.data)
          },
          (error) => {
            if (error.response && error.response.data) {
              const data = error.response.data
              if (data.detail && data.detail.failureUrl && data.detail.failureUrl.length > 0) {
                this.doRedirect(data.detail.failureUrl)
              } else {
                if (data?.detail?.errorCode === "110") {
                  this.startAuthentication()
                  this.error = "messages.authentication_timeout"
                } else {
                  this.error = "messages.login_failure"
                }
              }
            }
          }
        )
    },

    doRedirect (url) {
      if (url) {
        url.search = this.updateLocale(url.search)
        window.location.href = url
      }
    },

    getMessageKey (message) {
      const messageKey = message.trim()
        .toLowerCase()
        .split(" ").join("_")
        .split(".").join("")
        .split(":").join("")
        .split("\"").join("")
      return "messages." + messageKey
    },

    getUrl,

    getTargetUrlParam () {
      if (this.$route.query.goto) {
        const arr = this.$route.query.goto.split("?")
        if (arr.length > 1) {
          const querystring = arr[1]
          const params = querystring.split("&")
          let targetURL = null
          params.forEach(function (param) {
            if (param.indexOf("targetURL=") === 0) {
              targetURL = param
            }
          })
          if (targetURL) {
            return "&" + targetURL
          }
        }
      }
      return ""
    },

    initFrontend,

    onAuthenticate (data) {
      if (data) {
        if (data.tokenId) {
          let redirect = ""
          if (this.$route.query.goto) {
            redirect = this.$route.query.goto
          } else if (data.successUrl && data.successUrl.length > 0) {
            redirect = data.successUrl
          } else {
            redirect = "https://asfinag.at"
          }
          window.location.href = redirect
        } else if (data.callbacks) {
          this.auth = data
        }
      }
    },

    updateLocale (queryString) {
      const searchParams = new URLSearchParams(queryString)
      searchParams.set("locale", this.$i18n.locale)
      return searchParams.toString()
    },

    setError (msg) {
      this.error = msg
    },

    startAuthentication () {
      this.error = null
      axios
        .post(this.authenticateUrl, {}, {
          headers: {
            "Accept-API-Version": "protocol=1.0,resource=2.1"
          }
        })
        .then(
          (response) => {
            if (response.status === 200 && response.data) {
              this.onAuthenticate(response.data)
            } else {
              this.$router.push("/error")
            }
          },
          (error) => {
            if (error && error.response && error.response.data) {
              const data = error.response.data
              if (data.detail && data.detail.failureUrl && data.detail.failureUrl.length > 0) {
                // redirect to failureUrl
                this.doRedirect(data.detail.failureUrl)
              } else if (data.message) {
                this.setError(this.getMessageKey(data.message))
              }
            } else {
              this.$router.push("/error")
            }
          }
        )
    },

    submitForm () {
      this.doAuthenticate(this.auth)
    }
  }
}
</script>

<style>
  @import '../assets/css/kw-myasf-login.min.css';
</style>
