// Transpiler for older browsers
import "core-js/stable"
import "regenerator-runtime/runtime"

import axios from "axios"

import App from "./App.vue"
import { createApp } from "vue"
import { createI18n } from "./i18n"
import { createRouter } from "./router"
import { initConfig } from "./config"

// Load config
await Promise.all([
	axios.get("/login/config.json")
]).then(function (results) {
	initConfig(results[0].data)
})

// Initialize
const app = createApp(App)
app.use(createRouter())
app.use(createI18n())

// Start Vue
app.mount("#app")
