<template>
	<main>
		<div class="error">
			<div class="container">
				<div class="error--icon">
					<span class="asf-close-outlinewarning"></span>
				</div>
				<h1 class="error--title">{{$t('error.404.headline')}}</h1>
				<p class="error--paragraph">{{$t('error.404.infoText')}}</p>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "Error404"
}
</script>
