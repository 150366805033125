<template>
<div class="page-header-wrapper js-page-header">
    <header id="page-header" class="page-header">
        <div class="container-full-width header-container">
            <div class="asfinag-logo-block">
              <div class="logo">
                  <span class="sr-only">ASFINAG</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="233" height="26" viewBox="0 0 683.5 75.4" class="img-responsive">
                      <path class="st01" d="M367.4 1.3h8.6v72.9h-8.6zM589.9 1.3h8.6v72.9h-8.6zM480.7 1.3h8.6v72.9h-8.6zM291.1 1.3h8.6v72.9h-8.6zM192.3 1.3h8.6v72.9h-8.6zM86.8 1.3h8.6v72.9h-8.6z"/>
                      <path class="st1" d="M400.3 74.1V1.3h14.3l29.8 48.6V1.3h13.7v72.8h-14.8l-29.4-47.5v47.5zM46 45.3l-10-27-9.8 27H46zm27.1 28.8h-16l-6.3-16.5H21.6l-6 16.5H0L28.4 1.3H44l29.1 72.8zM114.3 50.4l14.3-1.4c.9 4.8 2.6 8.3 5.2 10.6 2.6 2.3 6.2 3.4 10.7 3.4 4.7 0 8.3-1 10.7-3 2.2-1.7 3.5-4.2 3.6-7 0-1.6-.5-3.2-1.5-4.4-1.5-1.5-3.3-2.6-5.3-3.1-1.7-.6-5.6-1.7-11.8-3.2-7.9-2-13.4-4.4-16.6-7.2-4.3-3.6-6.8-9-6.7-14.7 0-3.7 1.1-7.3 3.1-10.4 2.2-3.3 5.4-5.9 9.1-7.4 4.6-1.8 9.4-2.7 14.3-2.5 9.1 0 16 2 20.6 6s7.1 9.3 7.3 16l-14.7.6c-.6-3.7-2-6.4-4.1-8.1s-5.2-2.5-9.3-2.5c-4.1 0-7.6.9-10 2.6-1.5 1-2.4 2.7-2.3 4.5 0 1.7.8 3.4 2.2 4.4 1.9 1.6 6.4 3.2 13.5 4.9 7.2 1.7 12.5 3.4 15.9 5.2 3.3 1.7 6.1 4.3 8 7.4 2 3.5 3 7.5 2.9 11.6 0 4.2-1.2 8.2-3.5 11.7-2.4 3.7-5.8 6.5-9.8 8.1-4.2 1.8-9.5 2.7-15.9 2.7-9.2 0-16.3-2.1-21.2-6.4-4.8-4.1-7.7-10.3-8.7-18.4M222.6 74.1V1.3h49.9v12.3h-35.2v17.2h30.4v12.4h-30.4v30.9zM326.2 1.3h14.7v12.3h-14.7zM326.2 25.9h14.7v48.2h-14.7zM549.1 45.3l-10-27-9.8 27h19.8zm27.1 28.8h-16l-6.4-16.5h-29.1l-6 16.5h-15.6l28.4-72.9H547l29.2 72.9zM651.8 47.3V35.1h31.7v29c-3.1 3-7.5 5.6-13.4 7.9-5.7 2.2-11.7 3.4-17.8 3.4-6.9.2-13.8-1.5-19.9-4.8-5.6-3.1-10.1-7.9-12.8-13.7-2.9-6.1-4.3-12.7-4.3-19.4-.1-7 1.5-14 4.8-20.2 3.1-5.9 8-10.6 14-13.6 4.7-2.4 10.5-3.6 17.4-3.6 9 0 16.1 1.9 21.2 5.7 5.1 3.8 8.6 9.4 9.8 15.7L668 24.2c-.9-3.4-3-6.4-5.8-8.5-3.1-2.2-6.8-3.2-10.6-3.1-6.4 0-11.5 2-15.3 6.1-3.8 4.1-5.7 10.1-5.7 18.1 0 8.7 1.9 15.2 5.8 19.5 3.8 4.3 8.9 6.5 15.1 6.5 3.2 0 6.3-.6 9.3-1.8 2.9-1.1 5.6-2.6 8-4.4v-9.2l-17-.1z"/>
                  </svg>
                  <span class="logo-addon">{{$t('header.my_account')}}</span>
              </div>
            </div>

            <div class="nav-login">
                <ul class="nav-login__list">
                    <li class="nav-login__item nav-login__item--mobile">
                        <button class="header-btn js-adjust-contrast">
                            <span class="icon asf-ccontrast" aria-hidden="true"></span>
                            <span class="nav-login__name site-contrast-on">{{$t('header.contrast_on')}}</span>
                            <span class="nav-login__name site-contrast-off">{{$t('header.contrast_off')}}</span>
                        </button>
                    </li>

                    <li class="nav-login__item nav-login__item--mobile js-header-dd-menu">
                        <button class="header-btn ln-switcher js-header-dd-toggle">
                            <span class="icon-lang current" title="$t('languages.' + $i18n.locale">{{$i18n.locale}}</span>
                            <span class="nav-login__name">{{$t('header.language')}}</span>
                        </button>
                        <ul class="header-dd-content js-header-dd-content">
                            <li class="ln-item"
                                v-for="locale in locales"
                                v-bind:key="locale">
                                <a href="#" class="ln-link" title="$t('languages.' + locale)" v-on:click.prevent="changeLocale(locale)">{{$t('languages.' + locale)}}</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-login__item nav-login__item--more">
                        <button class="nav-login__link js-menu">
                            <span class="icon asf-more"></span>
                            <span class="nav-login__name">mehr</span>
                        </button>
                    </li>
                </ul>

                <div class="nav-login__wrap">
                    <div class="nav-login__menu">
                        <span class="js-close asf-close"></span>
                        <div class="nav-login__section nav-login__section--mobile">
                            <ul class="nav-login__section-icons">
                                <li class="nav-login__item">
                                    <a href='#' class="nav-login__link nav-contrast js-adjust-contrast">
                                        <span class="icon asf-ccontrast" aria-hidden="true"></span>
                                        <span class="nav-login__name site-contrast-on">{{$t('header.contrast_on')}}</span>
                                        <span class="nav-login__name site-contrast-off">{{$t('header.contrast_off')}}</span>
                                    </a>
                                </li>
                                <li class="nav-login__item js-header-dd-menu">
                                    <a href="javascript:;" class="nav-login__link ln-switcher js-header-dd-toggle">
                                        <span class="icon-lang current" title="$t('languages.' + $i18n.locale">{{$i18n.locale}}</span>
                                        <span class="nav-login__name">{{$t('header.language')}}</span>
                                    </a>
                                    <ul class="header-dd-content js-header-dd-content">
                                        <li class="ln-item"
                                            v-for="locale in locales"
                                            v-bind:key="locale">
                                            <a href="#" class="ln-link" title="$t('languages.' + locale)" v-on:click.prevent="changeLocale(locale)">{{$t('languages.' + locale)}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <ul class="nav-login__section-icons">
                            <li class="nav-login__item">
                                <a v-bind:href="getUrl('services_asfinag', $i18n.locale)" class="nav-login__item-link">
                                    <span class="round-icon asf-and-logo" aria-hidden="true"></span>
                                    <span class="nav-login__item-name">{{$t('services.asfinag')}}</span>
                                </a>
                            </li>
                            <li class="nav-login__item">
                                <a v-bind:href="getUrl('services_shop', $i18n.locale)" class="nav-login__item-link">
                                    <span class="round-icon asf-shopping-cart-full" aria-hidden="true"></span>
                                    <span class="nav-login__item-name">{{$t('services.shop')}}</span>
                                </a>
                            </li>
                            <li class="nav-login__item">
                                <a v-bind:href="getUrl('services_app', $i18n.locale)" class="nav-login__item-link">
                                    <span class="round-icon asf-app-download" aria-hidden="true"></span>
                                    <span class="nav-login__item-name">{{$t('services.app')}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</div>
</template>

<script>
import { getUrl } from "@/config"
import { resetForms } from "../assets/js/kw-myasf-login-lib.js"
import { availableLocales, setCookie } from "@/i18n"

export default {
  name: "Header",

  computed: {
    locales() {
      const locales = []
      availableLocales().forEach(locale => {
        if (this.$i18n.locale !== locale) {
          locales.push(locale)
        }
      })
      return locales
    }
  },

  methods: {
    getUrl,

    async changeLocale (locale) {
      if (locale && availableLocales().includes(locale)) {
        this.$i18n.locale = locale
        setCookie(locale)
        document.documentElement.setAttribute("lang", locale.slice(0, 2))
        // document.title = this.$i18n.t("header.title")
        resetForms()
      }
    }
  }
}
</script>
