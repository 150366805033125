<template>
<footer id="page-footer" class="w-page-footer" tabindex="-1">
    <div class="footer-darken"></div>
    <div class="js-sticky-top footer-top">
        <div class="js-sticky-boxes footer-boxes footer-boxes--nochat js-accordion" id="sticky-footer-accordion">
            <a v-bind:href="getUrl('services_asfinag', $i18n.locale)" target="_blank" class="footer-contact">
                <span class="footer-contact__icon footer-contact__icon--orange">
                    <span class="asf-and-logo"></span>
                </span>
                <span class="footer-contact__text">{{$t('services.asfinag')}}</span>
            </a>
            <a v-bind:href="getUrl('services_shop', $i18n.locale)" target="_blank" class="footer-contact">
                <span class="footer-contact__icon footer-contact__icon--orange">
                    <span class="asf-shopping-cart-full"></span>
                </span>
                <span class="footer-contact__text">{{$t('services.shop')}}</span>
            </a>
            <a v-bind:href="getUrl('services_app', $i18n.locale)" target="_blank" class="footer-contact">
                <span class="footer-contact__icon footer-contact__icon--orange">
                    <span class="asf-app-download"></span>
                </span>
                <span class="footer-contact__text">{{$t('services.app')}}</span>
            </a>
        </div>
    </div>

    <div class="footer-copyright">
        <div class="footer-logo">
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="180px" height="20px" viewBox="0 0 684.8 76.7" style="enable-background: new 0 0 684.8 76.7" xml:space="preserve">
                <g>
                    <rect x="87.8" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <rect x="193.2" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <rect x="292.1" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <rect x="368.4" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <rect x="481.6" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <rect x="590.8" y="1.3" class="st0" width="6.8" height="72.9"/>
                    <polygon
                        class="st0"
                        points="400.3,74.2 400.3,1.3 414.6,1.3 444.4,50 444.4,1.3 458.1,1.3 458.1,74.2 443.3,74.2 413.9,26.7
                        413.9,74.2 	"
                    />
                    <path class="st0" d="M46,45.3l-10-27l-9.8,27H46z M73.1,74.2h-16l-6.3-16.5H21.6l-6,16.5H0L28.4,1.3h15.6L73.1,74.2z" />
                    <path
                        class="st0"
                        d="M114.3,50.4l14.3-1.4c0.9,4.8,2.6,8.3,5.2,10.6c2.6,2.3,6.2,3.4,10.7,3.4c4.7,0,8.3-1,10.7-3
                        c2.2-1.7,3.5-4.2,3.6-7c0-1.6-0.5-3.2-1.5-4.4c-1.5-1.5-3.3-2.6-5.3-3.1c-1.7-0.6-5.6-1.7-11.8-3.2c-7.9-2-13.4-4.4-16.6-7.2
                        c-4.3-3.6-6.8-9-6.7-14.7c0-3.7,1.1-7.3,3.1-10.4c2.2-3.3,5.4-5.9,9.1-7.4c4.6-1.8,9.4-2.7,14.3-2.5c9.1,0,16,2,20.6,6
                        s7.1,9.3,7.3,16l-14.7,0.6c-0.6-3.7-2-6.4-4.1-8.1s-5.2-2.5-9.3-2.5c-4.1,0-7.6,0.9-10,2.6c-1.5,1-2.4,2.7-2.3,4.5
                        c0,1.7,0.8,3.4,2.2,4.4c1.9,1.6,6.4,3.2,13.5,4.9c7.2,1.7,12.5,3.4,15.9,5.2c3.3,1.7,6.1,4.3,8,7.4c2,3.5,3,7.5,2.9,11.6
                        c0,4.2-1.2,8.2-3.5,11.7c-2.4,3.7-5.8,6.5-9.8,8.1c-4.2,1.8-9.5,2.7-15.9,2.7c-9.2,0-16.3-2.1-21.2-6.4
                        C118.2,64.8,115.3,58.6,114.3,50.4"
                    />
                    <polygon
                        class="st0"
                        points="222.6,74.2 222.6,1.3 272.5,1.3 272.5,13.6 237.3,13.6 237.3,30.9 267.7,30.9 267.7,43.2 237.3,43.2
                        237.3,74.2 	"
                    />
                    <rect x="326.2" y="1.3" class="st0" width="14.7" height="12.3"/>
                    <rect x="326.2" y="26" class="st0" width="14.7" height="48.2"/>
                    <path class="st0" d="M549.1,45.3l-10-27l-9.8,27H549.1z M576.2,74.2h-16l-6.4-16.5h-29.1l-6,16.5h-15.6l28.4-72.9H547L576.2,74.2z" />
                    <path
                            class="st0"
                            d="M651.8,47.4V35.1h31.7v29c-3.1,3-7.5,5.6-13.4,7.9c-5.7,2.2-11.7,3.4-17.8,3.4c-6.9,0.2-13.8-1.5-19.9-4.8
            c-5.6-3.1-10.1-7.9-12.8-13.7c-2.9-6.1-4.3-12.7-4.3-19.4c-0.1-7,1.5-14,4.8-20.2c3.1-5.9,8-10.6,14-13.6
            c4.7-2.4,10.5-3.6,17.4-3.6c9,0,16.1,1.9,21.2,5.7c5.1,3.8,8.6,9.4,9.8,15.7L668,24.2c-0.9-3.4-3-6.4-5.8-8.5
            c-3.1-2.2-6.8-3.2-10.6-3.1c-6.4,0-11.5,2-15.3,6.1c-3.8,4.1-5.7,10.1-5.7,18.1c0,8.7,1.9,15.2,5.8,19.5c3.8,4.3,8.9,6.5,15.1,6.5
            c3.2,0,6.3-0.6,9.3-1.8c2.9-1.1,5.6-2.6,8-4.4v-9.2L651.8,47.4z"
                    />
            </g>
          </svg>
        </div>
        <div class="footer-copyright__content">
            <div class="footer-copyright__top">
                <ul class="footer-copyright__list">
                    <li class="footer-copyright__item">
                        <a v-bind:href="getUrl('footer_contact', $i18n.locale)" class="footer-copyright__link">{{$t('footer.contact')}}</a>
                    </li>
                    <li class="footer-copyright__item">
                        <a v-bind:href="getUrl('footer_privatePolicy', $i18n.locale)" class="footer-copyright__link">{{$t('footer.dataProtection')}}</a>
                    </li>
                    <li class="footer-copyright__item">
                        <a v-bind:href="getUrl('footer_imprint', $i18n.locale)" class="footer-copyright__link">{{$t('footer.imprint')}}</a>
                    </li>
                </ul>
            </div>
            <div class="footer-copyright__bottom">
                <p class="footer-copyright__paragraph" v-html="$t('footer.text')" />
            </div>
        </div>
        <div class="footer-copyright__links">
            <a v-bind:href="getUrl('social_facebook', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.facebook')">
                <span class="sr-only">{{$t('social.facebook')}}</span>
                <span class="asf-facebook"></span>
            </a>
            <a v-bind:href="getUrl('social_instagram', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.instagram')">
                <span class="sr-only">{{$t('social.instagram')}}</span>
                <span class="asf-instagram"></span>
            </a>
            <a v-bind:href="getUrl('social_twitter', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.twitter')">
                <span class="sr-only">{{$t('social.twitter')}}</span>
                <span class="asf-twitter"></span>
            </a>
            <a v-bind:href="getUrl('social_linkedin', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.linkedin')">
                <span class="sr-only">{{$t('social.linkedin')}}</span>
                <span class="asf-linkedin"></span>
            </a>
            <a v-bind:href="getUrl('social_youtube', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.youtube')">
                <span class="sr-only">{{$t('social.youtube')}}</span>
                <span class="asf-youtube"></span>
            </a>
            <a v-bind:href="getUrl('social_blog', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.blog')">
                <span class="sr-only">{{$t('social.blog')}}</span>
                <span class="asf-blog"></span>
            </a>
            <a v-bind:href="getUrl('social_android', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.android')">
                <span class="sr-only">{{$t('social.android')}}</span>
                <span class="asf-android"></span>
            </a>
            <a v-bind:href="getUrl('social_ios', $i18n.locale)" target="_blank" rel="noopener" class="footer-copyright__conect" :title="$t('social.ios')">
                <span class="sr-only">{{$t('social.ios')}}</span>
                <span class="asf-ios"></span>
            </a>
        </div>
    </div>
</footer>
</template>

<script>
import { getUrl } from "@/config"

export default {
  name: "Footer",

  methods: {
    getUrl
  }
}
</script>
