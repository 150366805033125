import { createI18n as _createI18n } from "vue-i18n"

const cookieName = "i18n"
const defaultLocale = "en"

export function availableLocales() {
	return [
		"de",
		"en",
		"fr",
		"it",
		"nl",
		"da",
		"cs",
		"sl",
		"hu",
		"sk",
		"hr",
		"ro",
		"pl",
		"tr"
	]
}

export function getCookie() {
	return document.cookie
			.split("; ")
			.find((row) => row.startsWith(cookieName + "="))
			?.split("=")[1]
}

export function setCookie(value) {
	const cookieValue = cookieName + "=" + value
	document.cookie = cookieValue
}

function loadLocaleMessages() {
	const locales = require.context("../assets/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

function getStartingLocale() {
	let locale = null

	// Cookie (i18n)
	if (!locale) {
		const cookieLocale = getCookie()
		if (cookieLocale) {
			locale = cookieLocale.trim().split(/-|_/)[0]
		}
	}

	// Query Parameter (locale)
	if (!locale) {
		const queryLocale = new URLSearchParams(window.location.search).get("locale")
		if (queryLocale) {
			locale = queryLocale.trim().split(/-|_/)[0]
		}
	}

	// Browser Default
	if (!locale) {
		const navigatorLocale =
			navigator.languages !== undefined
				? navigator.languages[0]
				: navigator.language
		if (navigatorLocale) {
			locale = navigatorLocale.trim().split(/-|_/)[0]
		}
	}

	// HTML
	if (!locale) {
		const htmlLocale = document.documentElement.getAttribute("lang")
		if (htmlLocale) {
			locale = htmlLocale.trim().split(/-|_/)[0]
		}
	}

	// Default
	if (!locale) {
		locale = defaultLocale
	}

	if (availableLocales().includes(locale)) {
		return locale
	} else {
		return defaultLocale
	}
}

export function createI18n() {
	return _createI18n({
		locale: getStartingLocale(),
		fallbackLocale: defaultLocale,
		messages: loadLocaleMessages()
	})
}
