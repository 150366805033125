import { createRouter as _createRouter, createWebHistory } from "vue-router"

import Error404 from "../components/Error404.vue"
import Error500 from "../components/Error500.vue"
import UserConsent from "../components/UserConsent.vue"
import UserLogin from "../components/UserLogin.vue"

const routes = [
	{
		path: "/:pathMatch(.*)*",
		component: Error404
	},
	{
		path: "/",
		component: UserLogin
	},
	{
		path: "/login",
		component: UserLogin
	},
	{
		path: "/error",
		component: Error500
	},
	{
		path: "/oauth2/realms/root/realms/consumer/authorize",
		component: UserConsent
	},
	{
		path: "/login/oauth2/realms/root/realms/consumer/authorize",
		component: UserConsent
	}
]

export function createRouter() {
	return _createRouter({
		history: createWebHistory(process.env.BASE_URL),
		scrollBehavior() {
			return {
				left: 0,
				top: 0
			}
		},
		routes
	})
}
