<template>
<main>
    <div class="container-narrow login margin--top-lg margin--bottom-xl">
        <h1 class="login__header margin--bottom-md align--center">{{$t('consent.headline')}}</h1>
        <div class="consent-box--big">
            <h2 class="consent-box--big-header">{{$t('consent.intro')}}</h2>
            <div class="editor-output consent-box--big-scroller">
                <div v-for="scope in scopes" v-bind:key="scope">
                    <h3>
                        <b>{{$t('consent.scopes.' + scope + '.name')}}</b>
                    </h3>
                    <p>{{$t('consent.scopes.' + scope + '.text')}}</p>
                </div>
            </div>
            <div class="consent-box--big-footer">
                {{$t('consent.message')}}
                <div class="consent-box--big-buttons margin--top-md">
                    <button class="btn btn--lg btn--secondary" v-on:click.prevent="decide('deny')">{{$t('consent.reject')}}</button>
                    <button class="btn btn--lg btn--primary float--right" v-on:click.prevent="decide('allow')">{{$t('consent.accept')}} & {{$t('consent.next')}}</button>
                </div>
            </div>
            <form id="consentForm" v-bind:action="consent.formTarget" method="post">
                <input type="hidden" name="scopes" v-bind:value="consent.scopes.join(' ')">
                <input type="hidden" name="responseType" v-bind:value="consent.responseType">
                <input type="hidden" name="clientId" v-bind:value="consent.clientId">
                <input type="hidden" name="csrf" v-bind:value="consent.csrf">
                <input type="hidden" name="acr" v-bind:value="consent.acr">
                <input type="hidden" name="save_consent" v-bind:value="consent.save_consent">
                <input type="hidden" name="redirectUri" v-bind:value="consent.redirectUri">
                <input type="hidden" name="decision" v-bind:value="consent.decision">
            </form>
        </div>
    </div>
</main>
</template>

<script>
import axios from "axios"

import { initFrontend } from "../assets/js/kw-myasf-login-lib.js"
import { nextTick } from "vue"

export default {
  name: "UserConsent",

  data: function () {
    return {
      consent: {
        scopes: [],
        acr: null,
        responseType: null,
        clientId: null,
        csrf: null,
        redirectUri: null,
        save_consent: false,
        formTarget: null,
        decision: null,
        hiddenScopes: [
          "openid",
          "sl.user-svc"
        ]
      }
    }
  },

  created: function () {
    // get data
    axios
      .get("/auth/oauth2/realms/root/realms/consumer/authorize" + window.location.search)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          const match = data.match(/pageData\s=\s{([\s\S]*)};/)
          if (match) {
            this.consent.scopes = data.match(/pageData\s=\s{([\s\S]*)scope:\s"([\s\S]*?)"([\s\S]*)};/)[2].split(" ")
            this.consent.responseType = data.match(/responseType:\s"(.*)"/)[1]
            this.consent.clientId = data.match(/clientId:\s"(.*)"/)[1]
            this.consent.csrf = data.match(/csrf:\s"(.*)"/)[1]
            this.consent.acr = data.match(/acr:\s"(.*)"/)[1]
            this.consent.redirectUri = data.match(/redirectUri:\s"(.*)"/)[1]
            this.consent.save_consent = data.match(/isSaveConsentEnabled:\strue/).length > -1 ? "on" : "off"
            this.consent.formTarget = data.match(/formTarget:\s"\\(.*)"/)[1]
          } else {
            this.redirectOrFail(response.data)
          }
        }
      }, (error) => {
        this.redirectOrFail(error.response.data)
      })
  },

  mounted: async function () {
    // call the imported function
    // nextTick() will postpone the function call to the next render cycle to
    // be on the safe side in case other DOM manioulations happen in mounted,
    // make sure to run this function last
    await nextTick()
    initFrontend()
  },

  computed: {
    scopes() {
      const scopes = []
      this.consent.scopes.forEach(scope => {
        if (this.consent.hiddenScopes.indexOf(scope) === -1) {
          scopes.push(scope)
        }
      })
      return scopes
    }
  },

  methods: {
    decide (answer) {
      this.consent.decision = answer
      this.$nextTick(function () {
        document.getElementById("consentForm").submit()
      })
    },

    redirectOrFail (data) {
      let description = "unknown_error"
      let message = "error_occured"

      if (data && typeof data === "string") {
        const match = data.match(/pageData\s=\s{/)
        if (match) {
          description = data.match(/description:\s"(.*)"/)[1]
          message = data.match(/message:\s"(.*)"/)[1]
        }
      }

      let url = "/"
      if (this.$route.query.redirect_uri) {
        url = this.$route.query.redirect_uri
      } else if (this.$route.query.goto) {
        url = this.$route.query.goto
      }
      url += url.indexOf("?") > -1 ? "&" : "?"
      url += "error=" + message + "&error_description=" + description

      if (this.$route.query.state) {
        url += "&state=" + this.$route.query.state
      }

      if (this.$route.query.nonce) {
        url += "&nonce=" + this.$route.query.nonce
      }

      window.location.href = url
    }
  }
}
</script>

<style>
  @import '../assets/css/kw-myasf-login.min.css';
</style>
