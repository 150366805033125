<template>
  <div v-bind:class="{hidden: !pageReady}" style="display: flex; flex-direction: column; height: 100%;">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer"

export default {
  name: "app",

  components: {
    Header,
    Footer
  }
}
</script>
